<template>
  <div>
    <div>
      <div class="container mx-auto lg:flex mb-10">
        <div class="lg:w-1/2 p-28 lg:pl-20 text-center lg:text-left">
          <h2
            class="
              font-bold
              uppercase
              tracking-tight
              text-2xl text-webmoney
              dark:text-blue-400
              mb-4
            "
            v-html="$t('ext.welcome.chrome.title')"
          ></h2>
          <p
            class="
              text-xl text-gray-500
              dark:text-gray-400
              mb-10
              lg:w-2/3
              w-full
            "
          >
            {{ $t("ext.welcome.chrome.subtitle") }}
          </p>
        </div>
        <div class="lg:w-1/2 w-full p-10 lg:p-20 self-start sm:block hidden">
          <div
            class="
              main-container
              h-96
              bg-white
              flex flex-col
              w-full
              rounded-md
              border-gray-300 border
              dark:border-gray-700
              overflow-hidden
              relative
            "
          >
            <div
              class="
                browser-cursor
                w-5
                h-5
                bg-yellow-200
                rounded-full
                opacity-80
                absolute
                top-1/3
                -right-10
                z-20
              "
            >
              <MousePointerIcon class="w-5 h-5" />
            </div>

            <div
              class="browser-header bg-gray-300 h-14 w-full flex flex-shrink-0"
            >
              <div class="browser-buttons flex space-x-3 items-center px-4">
                <div
                  class="browser-button bg-red-500 w-4 h-4 rounded-full"
                ></div>
                <div
                  class="browser-button bg-yellow-400 w-4 h-4 rounded-full"
                ></div>
                <div
                  class="browser-button bg-green-500 w-4 h-4 rounded-full"
                ></div>
              </div>
              <div
                class="
                  browser-page-header
                  bg-white
                  rounded-t-md
                  w-1/3
                  h-4/5
                  self-end
                "
              ></div>
            </div>

            <div
              class="
                extinsions
                w-full
                h-12
                flex
                justify-end
                items-center
                space-x-4
                flex-shrink-0
                pr-3
                text-gray-500
                dark:text-gray-400
              "
            >
              <img
                src="@/assets/images/logo-mini.svg"
                alt="logo"
                class="extensions-logo w-6 h-6"
              />
              <div class="relative z-10">
                <img
                  src="@/assets/images/puzzle.svg"
                  alt="puzzle"
                  class="w-6 h-6"
                />
                <div
                  class="
                    extensions-circle
                    absolute
                    top-0
                    left-0
                    rounded-full
                    w-full
                    h-full
                    z-0
                  "
                ></div>
              </div>
              <MoreVerticalIcon class="w-6 h-6 fill-current" />
            </div>

            <div
              class="
                browser-content
                h-full
                mx-14
                mb-10
                border-gray-200 border-2
                dark:border-gray-700
                rounded-md
                p-4
                flex flex-col
                justify-between
              "
            >
              <h5 class="text-xl">
                {{ $t("ext.welcome.chrome.extensions") }}
              </h5>
              <div class="w-1/3 h-6 bg-gray-200 rounded-md"></div>
              <div class="w-full h-4 bg-gray-200 rounded-md"></div>
              <div class="w-2/5 h-4 bg-gray-200 rounded-md"></div>
              <div
                class="
                  flex
                  w-full
                  items-center
                  text-gray-500
                  dark:text-gray-400
                "
              >
                <img
                  src="@/assets/images/logo-mini.svg"
                  alt="logotip"
                  class="w-6 h-6 mr-4"
                />
                <h6 class="mr-auto">WebMoney</h6>
                <div class="relative mr-4">
                  <img
                    class="pin"
                    height="20px"
                    width="20px"
                    src="@/assets/images/paper-push-pin.svg"
                    alt="pin"
                  />
                  <div
                    class="
                      pin-circle
                      absolute
                      top-0
                      left-0
                      rounded-full
                      w-full
                      h-full
                    "
                  ></div>
                </div>
                <MoreVerticalIcon class="w-6 h-6 fill-current" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-gray-100 dark:bg-gray-700 overflow-hidden">
      <div class="container mx-auto mb-10 p-20">
        <div class="flex lg:flex-nowrap flex-wrap items-center relative mb-20">
          <div
            class="
              lg:w-2/5
              w-full
              ml-auto
              lg:text-left
              text-center
              lg:mb-0
              mb-10
            "
          >
            <p
              class="
                font-bold
                uppercase
                tracking-tight
                text-2xl text-webmoney
                dark:text-blue-400
                mb-4
              "
            >
              {{ $t("ext.welcome.chrome.content.title-1") }}
            </p>
            <p class="text-xl text-gray-500 dark:text-gray-400">
              {{ $t("ext.welcome.chrome.content.article-1") }}
            </p>
          </div>
          <img
            class="lg:w-3/5 w-full lg:pl-20 flex justify-end relative z-20"
            :src="require(`@/assets/images/overview-tab-${$i18n.locale}.png`)"
            alt="depth"
          />
        </div>

        <div
          class="
            flex
            lg:flex-nowrap
            flex-wrap-reverse
            relative
            z-20
            items-center
            mb-20
          "
        >
          <img
            class="lg:w-3/5 w-full lg:pr-20 relative z-20"
            :src="require(`@/assets/images/traffic-tab-${$i18n.locale}.png`)"
            alt="traffic"
          />
          <div class="lg:w-2/5 w-full lg:text-left text-center lg:mb-0 mb-10">
            <p
              class="
                font-bold
                uppercase
                tracking-tight
                text-2xl text-webmoney
                dark:text-blue-400
                mb-4
              "
            >
              {{ $t("ext.welcome.chrome.content.title-2") }}
            </p>
            <p class="text-xl text-gray-500 dark:text-gray-400">
              {{ $t("ext.welcome.chrome.content.article-2") }}
            </p>
          </div>
        </div>

        <div class="flex flex-wrap lg:flex-nowrap relative z-10 items-center">
          <div
            class="
              lg:w-2/5
              w-full
              ml-auto
              text-center
              lg:text-left lg:mb-0
              mb-10
            "
          >
            <p
              class="
                font-bold
                uppercase
                tracking-tight
                text-2xl text-webmoney
                dark:text-blue-400
                mb-4
              "
            >
              {{ $t("ext.welcome.chrome.content.title-3") }}
            </p>
            <p class="text-xl text-gray-500 dark:text-gray-400">
              {{ $t("ext.welcome.chrome.content.article-3") }}
            </p>
          </div>
          <img
            class="lg:w-3/5 w-full lg:pl-20 flex justify-end relative z-20"
            :src="require(`@/assets/images/geo-tab-${$i18n.locale}.png`)"
            alt="depth"
          />
        </div>
      </div>
    </div>
    <div>
      <TrustedExt />
    </div>
  </div>
</template>

<script>
import { MoreVerticalIcon, MousePointerIcon } from "vue-feather-icons";
import TrustedExt from "@/components/TrustedExt.vue";

export default {
  components: {
    MoreVerticalIcon,
    MousePointerIcon,
    TrustedExt,
  },
  computed: {},
  data() {
    return {
      root: process.env.VUE_APP_BACKEND_URL,
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.browser-content {
  animation: content-animation 4.5s infinite linear;
}
@keyframes content-animation {
  0%,
  35% {
    opacity: 0;
  }

  40%,
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.extensions-logo {
  animation: logo-animation 4.5s infinite linear;
}
@keyframes logo-animation {
  0%,
  70% {
    opacity: 0;
    transform: scale(0);
  }

  78% {
    opacity: 1;
    transform: scale(1.2);
  }
  80%,
  95% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.extensions-circle {
  animation: circle-animation 4.5s infinite linear;
}
@keyframes circle-animation {
  0% {
    transform: scale(1.4);
    border: 1px solid rgb(91, 170, 235);
    opacity: 1;
  }
  13%,
  30%,
  47% {
    transform: scale(2);
    opacity: 0;
  }
  14%,
  31% {
    transform: scale(1.4);
    opacity: 0;
  }
  15%,
  32% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    border: none;
  }
}
.pin-circle {
  animation: pin-circle-animation 4.5s infinite linear;
}
@keyframes pin-circle-animation {
  0%,
  49% {
    transform: scale(1.4);
  }
  50% {
    border: 1px solid rgb(91, 170, 235);
    opacity: 1;
  }
  58%,
  68%,
  78% {
    transform: scale(2);
    opacity: 0;
  }
  59%,
  69% {
    transform: scale(1.4);
    opacity: 0;
  }
  60%,
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.browser-cursor {
  animation: cursor-animation 4.5s infinite linear;
}
@keyframes cursor-animation {
  0% {
    transform: translate(0);
    transform: scale(1);
  }
  15%,
  45% {
    top: 80px;
    right: 45px;
    transform: scale(1);
  }
  24%,
  70% {
    transform: scale(1);
  }
  26%,
  72% {
    transform: scale(1.2);
  }
  28%,
  74% {
    transform: scale(1);
  }
  58%,
  100% {
    top: 310px;
    right: 110px;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.pin {
  animation: pin-animation 4.5s infinite linear;
}
@keyframes pin-animation {
  0%,
  70% {
    filter: brightness(0) saturate(100%) invert(0%) sepia(1%) saturate(7473%)
      hue-rotate(339deg) brightness(97%) contrast(98%);
  }
  72%,
  95% {
    filter: brightness(0) saturate(100%) invert(48%) sepia(94%) saturate(4169%)
      hue-rotate(186deg) brightness(104%) contrast(96%);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
